export default {
  DEFAULT: [],
  JAPAN_FLEX: [
    'c::dashboardWidget::MyCalendar',
    'c::dashboardWidget::NextLesson',
    'c::sidenav::calendar::student',
    'c::sidenav::myMedia',
  ],
  ON_DEMAND: [
    'c::dashboardWidget::MyCalendar',
    'c::dashboardWidget::NextLesson',
    'c::sidenav::calendar::student',
    'c::sidenav::scheduling::instructors',
    'c::sidenav::myMedia',
  ],
  ON_DEMAND_NO_WIDGETS: ['c::dashboardWidget::MyCalendar', 'c::dashboardWidget::NextLesson'],
  ON_DEMAND_GROUP_CONVO: ['c::sidenav::scheduling::instructors', 'c::sidenav::myMedia'],
  //NO_LP: ['c::sidenav::learningPath'],
  DISABLED_FOLLOW_INSTRUCTOR: ['c::sidenav::scheduling::instructors'],
  NO_MEDIA: ['c::sidenav::myMedia'],
  NO_ENROLLMENT: [
    'c::sidenav::dashboard::instructor',
    'c::sidenav::calendar::instructor',
    'c::sidenav::calendar::student',
    'c::sidenav::events',
    'c::sidenav::scheduling::instructors',
    'c::sidenav::instructors::sfstandard',
    'c::sidenav::instructors::salesforce',
    // 'c::sidenav::learningPath',
    // 'c::sidenav::myMedia',
    'c::sidenav::admin',
  ],
  NO_MY_PROGRESS: ['c::sidenav::myProgress::student'],
}
