import Router from 'next/router'
import { getQueryParams, urlStringify } from '@utils/helpers'
import { LMSLearningPlansRounds } from '@interfaces/LearningPath'

export const getCMID = (url: string = '') => getQueryParams('cmid', url)

export const getR1Activity = (activities: LMSLearningPlansRounds[] = []) =>
  activities.find(({ name, order }) => name === 'p5_student' && parseInt(order, 10) === 1)

export const redirectToCR8 = (
  id: string,
  url: string = '',
  activities: LMSLearningPlansRounds[] = [],
  activePage?: number
) => {
  const cmid = getCMID(url)
  const page = activePage != undefined ? activePage : parseInt(`${getQueryParams('page', url) || 0}`, 10)
  const r1cmid = getCMID(getR1Activity(activities)?.url)
  const params = { lpid: id, cmid, page, r1cmid }
  Router.push(`/activity?${urlStringify(params)}`)
}

export default {
  redirectToCR8,
}
