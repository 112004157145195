import StudentProfile from '@classes/StudentProfile'
import { UserProfileProps } from '@interfaces/UserProfile'
import {
  FEATURE_TYPE,
  LO_LODELIVERY_LEARNING_CENTER,
  LO_LODELIVERY_PROGRAMS,
  PROFILE_TYPE,
  PROGRAM_TYPES,
  REG_STATUS,
  VIRTUAL_CLASSROOM_PROVIDER,
} from '@utils/constants'
import {
  FETCH_PROFILE,
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_SUCCESS,
  REFETCH_PROFILE,
  UPDATE_INSTRUCTOR_INFO,
  UPDATE_STUDENT_PROGRAM,
  RESET_LAST_ACTIVE,
} from '../actions/userProfile'
import moment from 'moment'
import feature from '@pages/admin/page-settings/feature'
import { RegistrationInfo, RegistrationWrapper } from '@interfaces/Student'

export const initialState = {
  info: {} as UserProfileProps,
  isFetching: true,
  isSilentFetching: true,
  featureType: FEATURE_TYPE.DEFAULT,
  resetLastActive: false,
  lastDateActive: '',
}

function determineType(action) {
  const featType = [] as string[]
  const registrations = (action.profile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers || []).filter(
    ({ RegistrationInfo }) => RegistrationInfo.Status !== REG_STATUS.CANCELLED
  )
  const allRegistrations = action.profile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers || []
  const profile = new StudentProfile(action.profile?.StudentProfile?.StudentProfileInfo)
  const programs = profile?.RegistrationPortalPrograms?.programs
  const VIRTUAL_CLASSROOM_PROVIDERS = [VIRTUAL_CLASSROOM_PROVIDER.KALTURA, VIRTUAL_CLASSROOM_PROVIDER.NEWROW]
  const noProgramButHasMaterial =
    registrations.every((i) => !(i.RegistrationInfo.Programs || []).length) &&
    registrations.every((i) => (i.RegistrationInfo.Materials || []).length)
  const hasMedia = !!allRegistrations.filter((registration) =>
    registration.RegistrationInfo.Programs.some(
      (Program) => VIRTUAL_CLASSROOM_PROVIDERS.indexOf(Program.VirtualClassRoomProvider?.toLowerCase()) >= 0
    )
  ).length

  if (
    registrations.length > 0 &&
    noProgramButHasMaterial &&
    registrations.every((i) => PROGRAM_TYPES.JPN_FLEX.indexOf(i.RegistrationInfo.ProgramType) >= 0) &&
    action.profile?.Type === PROFILE_TYPE.STUDENT
  ) {
    //featType.push(FEATURE_TYPE.JAPAN_FLEX)
    featType.push(FEATURE_TYPE.NO_ENROLLMENT)
  } else if (
    registrations.length > 0 &&
    registrations.every((i) => PROGRAM_TYPES.ON_DEMAND.indexOf(i.RegistrationInfo.ProgramType) >= 0) &&
    action.profile?.Type === PROFILE_TYPE.STUDENT
  ) {
    const hasGroupConvo = registrations?.some((registration) => {
      return registration?.RegistrationInfo?.Programs?.some((i) => i.GroupConversationClasses)
    })
    if (hasGroupConvo) {
      featType.push(FEATURE_TYPE.ON_DEMAND_GROUP_CONVO)
    } else {
      featType.push(FEATURE_TYPE.ON_DEMAND)
    }
  } else if (registrations.length > 0 && registrations.every((i) => !(i.RegistrationInfo.Materials || []).length)) {
    featType.push(FEATURE_TYPE.NO_LP)
  } else {
    if (!registrations.length && action.profile?.Type === PROFILE_TYPE.STUDENT) {
      featType.push(FEATURE_TYPE.NO_ENROLLMENT)
    } else {
      featType.push(FEATURE_TYPE.DEFAULT)
    }
  }

  if (
    !programs?.some(
      (program) =>
        program.ProgramStudentInfo.ProgramType !== PROGRAM_TYPES.F2F[0] &&
        program.ProgramStudentInfo.ProgramType !== PROGRAM_TYPES.FLEX[0] &&
        program.ProgramStudentInfo.ProgramType !== PROGRAM_TYPES.JPN_FLEX[0] &&
        program.ProgramStudentInfo.ProgramType !== PROGRAM_TYPES.ON_DEMAND[0] &&
        program.ProgramStudentInfo.ProgramType !== PROGRAM_TYPES.ON_DEMAND_1[0]
    )
  ) {
    featType.push(FEATURE_TYPE.DISABLED_FOLLOW_INSTRUCTOR)
  }

  if (!hasMedia) {
    featType.push(FEATURE_TYPE.NO_MEDIA)
  }

  if (
    !registrations.some(
      (reg: RegistrationWrapper) =>
        LO_LODELIVERY_PROGRAMS.includes(reg?.RegistrationInfo?.ProgramType) &&
        LO_LODELIVERY_LEARNING_CENTER.includes(reg?.RegistrationInfo?.LearningCenterName)
    )
  ) {
    featType.push(FEATURE_TYPE.NO_MY_PROGRESS)
  }

  return featType
}

function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case REFETCH_PROFILE:
      return Object.assign({}, state, {
        isSilentFetching: true,
      })

    case FETCH_PROFILE:
      return Object.assign({}, state, {
        isFetching: true,
        isSilentFetching: true,
        info: {},
      })

    case FETCH_PROFILE_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        isSilentFetching: false,
        info: {},
      })

    case FETCH_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isSilentFetching: false,
        info: Object.assign({}, action.profile, { FeatureType: determineType(action) }),
      })

    case UPDATE_INSTRUCTOR_INFO:
      return Object.assign({}, state, {
        isFetching: false,
        isSilentFetching: false,
        info: {
          ...state.info,
          Timezone: action?.info?.PreferredTimezone ?? state.info.Timezone,
          InstructorProfile: {
            ...(state?.info?.InstructorProfile ?? {}),
            instructorInfo: {
              ...(state?.info?.InstructorProfile?.instructorInfo ?? {}),
              ...action.info,
            },
          },
        },
      })

    case UPDATE_STUDENT_PROGRAM:
      return {
        ...state,
        info: {
          ...state.info,
          StudentProfile: {
            ...(state?.info?.StudentProfile ?? {}),
            StudentProfileInfo: {
              ...(state?.info?.StudentProfile?.StudentProfileInfo ?? {}),
              ProgramStudentWrappers: (
                state?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers ?? []
              ).map(({ ProgramStudentInfo }) =>
                ProgramStudentInfo.ProgramId === action?.ProgramInfo?.ProgramId
                  ? {
                      ProgramStudentInfo: {
                        ...ProgramStudentInfo,
                        ...action.ProgramInfo,
                      },
                    }
                  : { ProgramStudentInfo }
              ),
            },
          },
        },
      }

    case RESET_LAST_ACTIVE:
      return {
        ...state,
        resetLastActive: action.resetLastActive,
        lastDateActive: action.resetLastActive
          ? moment.utc(moment.tz(state?.info.Timezone || moment.tz.guess())).format()
          : '',
      }

    default:
      return state
  }
}

export default userProfileReducer
