import { GlobalStyle, GlobalStyleReset } from '@berlitz/max-css-reset'
import theme from '@berlitz/max-theme-portals'
import GlobalStylePortals from '@components/GlobalStyle'
import SEO from '@components/SEO'
import AwsAuth from '@layouts/AwsAuth'
import NoSSRLayout from '@layouts/NoSSRLayout'
import PageLayout from '@layouts/PageLayout'
import PreloadImages from '@layouts/PreloadImages'
import welcomeLog from '@utils/welcome-log'
import App from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import '@components/AutoComplete/styleOverrides.css'
import '@components/Dropdown/styleOverrides.css'
import ActiveState from '@components/ActiveState'
import PageFooter from '@components/PageFooter'

class BerlitzPortalApp extends App {
  componentDidMount() {
    console.log('[_app.tsx] App mounted, pathname:', this.props.router.pathname)
    welcomeLog()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router.pathname !== this.props.router.pathname) {
      console.log('[_app.tsx] Route changed from', prevProps.router.pathname, 'to', this.props.router.pathname)
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log('[_app.tsx] CUSTOM ERROR HANDLING', error)
    // This is needed to render errors correctly in development / production
    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, router } = this.props
    console.log('[_app.tsx] Rendering app with route:', router?.state?.route)
    const routesWithoutFooter = {
      '/activity': true,
      '/_error': true,
    }
    return (
      <>
        <Head>
          <PreloadImages />
        </Head>

        {/**
         * For some reason, putting script inside Head component is not working anymore on newever versions of next.js
         * For more info: https://nextjs.org/docs/messages/no-script-tags-in-head-component
         */}
        {!process.env.CHINA_DEPLOYMENT && (
          <>
            <Script
              id="assign-cVer"
              strategy="beforeInteractive"
              dangerouslySetInnerHTML={{
                __html: `window.cVer = "${process.env.COMMIT_HASH}";`,
              }}
            />
            <Script
              id="portal-gtm"
              strategy="beforeInteractive"
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.GTM_ID}');`,
              }}
            />
          </>
        )}
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyleReset />
            <GlobalStyle />
            <GlobalStylePortals theme={theme} />
            <SEO
              description={process.env.SITE_DESCRIPTION}
              image={{
                url: process.env.SITE_IMAGE,
                description: process.env.SITE_DESCRIPTION,
                title: process.env.SITE_TITLE,
              }}
              title={process.env.SITE_TITLE}
              noMainTitle={true}
            />
            <NoSSRLayout>
              <AwsAuth>
                <ActiveState>
                  <PageLayout>
                    <>
                      <Component {...pageProps} />
                      {!routesWithoutFooter[router?.state?.route] ? <PageFooter /> : <></>}
                    </>
                  </PageLayout>
                </ActiveState>
              </AwsAuth>
            </NoSSRLayout>
          </>
        </ThemeProvider>
      </>
    )
  }
}

export default BerlitzPortalApp
